import React from 'react';
// import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import DateFnsUtils from '@date-io/date-fns';

import { ThemeProvider } from 'styled-components/macro';
import { create } from 'jss';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
	StylesProvider,
	ThemeProvider as MuiThemeProvider,
	jssPreset
} from '@material-ui/core/styles';

import createTheme from './theme';
import Routes from './routes/Routes';
import 'firebase/auth';

const jss = create({
	...jssPreset(),
	insertionPoint: document.getElementById('jss-insertion-point')
});

function App() {
	// const theme = useSelector((state) => state.themeReducer);
	return (
		<React.Fragment>
			<Helmet
				titleTemplate="%s | PostPerk"
				defaultTitle="App | PostPerk"
			/>
			<StylesProvider jss={jss}>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<MuiThemeProvider theme={createTheme('DEFAULT')}>
						<ThemeProvider theme={createTheme('DEFAULT')}>
							<Routes />
						</ThemeProvider>
					</MuiThemeProvider>
				</MuiPickersUtilsProvider>
			</StylesProvider>
		</React.Fragment>
	);
}

export default App;
