import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { FirebaseAppProvider } from 'reactfire';
import './mocks';

import { Provider } from 'react-redux';
import { store, persistedStore } from './redux/store/index';
import { PersistGate } from 'redux-persist/integration/react';

import Loader from './components/Loader';

const firebaseConfig = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_DB_URL,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET
};

ReactDOM.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistedStore}>
			<FirebaseAppProvider
				firebaseConfig={firebaseConfig}
				suspense={true}
			>
				<Suspense fallback={<Loader />}>
					<App />
				</Suspense>
			</FirebaseAppProvider>
		</PersistGate>
	</Provider>,
	document.getElementById('root')
);
