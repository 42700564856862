import { useFirestore, useFirestoreCollectionData, useUser } from 'reactfire';

const useOwnInvoice = () => {
	const { data: user } = useUser();
	const invoiceRef = useFirestore().collection('stripe-invoice');
	const invoiceQuery = invoiceRef.where('uid', '==', user.uid);
	const { data } = useFirestoreCollectionData(invoiceQuery);
	return data;
};

export default useOwnInvoice;
