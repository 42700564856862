import { useFirestore, useFirestoreCollectionData } from 'reactfire';

const usePartners = () => {
	const firestore = useFirestore();
	const partnerRef = firestore
		.collection('profile')
		.where('active', '==', true);

	const { data } = useFirestoreCollectionData(partnerRef, {
		idField: 'docId'
	});

	return data;
};

export default usePartners;
