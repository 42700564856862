import { useFirestore, useFirestoreCollectionData } from 'reactfire';

const useBrandConversion = (brandId) => {
	const firestore = useFirestore();
	const metricsRef = firestore.collection('conversion');
	const filteredQuery = metricsRef.where('brandId', '==', brandId);
	const { data } = useFirestoreCollectionData(filteredQuery, {
		idField: 'docId'
	});

	return data;
};

export default useBrandConversion;
