import { useFirestoreDocData, useFirestore } from 'reactfire';

const useOwnPlatform = (brandId, platform = 'null') => {
	const firestore = useFirestore();
	const userDetailsRef = firestore
		.collection('brand')
		.doc(brandId)
		.collection('integration')
		.doc(platform);
	const { data } = useFirestoreDocData(userDetailsRef, {
		idField: 'docId'
	});
	return data;
};

export default useOwnPlatform;
