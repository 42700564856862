import { useFirestoreCollectionData, useFirestore } from 'reactfire';

const useAllBrand = () => {
	const firestore = useFirestore();
	const brandRef = firestore.collection('brand');
	const { data } = useFirestoreCollectionData(brandRef, {
		idField: 'docId'
	});

	return data;
};

export default useAllBrand;
