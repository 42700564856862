import { useFirestoreDocData, useFirestore, useUser } from 'reactfire';

const useOwnBrand = () => {
	const { data: user } = useUser();
	const userDetailsRef = useFirestore().collection('user').doc(user.uid);

	const {
		data: { brand }
	} = useFirestoreDocData(userDetailsRef);

	const { data: ownBrand } = useFirestoreDocData(brand, { idField: 'docId' });

	return ownBrand;
};

export default useOwnBrand;
