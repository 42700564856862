import { useFirestore, useFirestoreDocData } from 'reactfire';

const useBrandMetrics = (brandId) => {
	const firestore = useFirestore();
	const metricsRef = firestore
		.collection('brand')
		.doc(brandId)
		.collection('metrics')
		.doc('all');

	const { data } = useFirestoreDocData(metricsRef, {
		idField: 'docId'
	});

	return data;
};

export default useBrandMetrics;
