import React from 'react';
import async from '../components/Async';
import { Redirect } from 'react-router-dom';
import {
	Briefcase,
	ShoppingBag,
	ShoppingCart,
	Settings as SettingsIcon,
	Gift,
	FileText,
	Users
} from 'react-feather';

// Guards
import { AuthCheck } from 'reactfire';
import { useOwnUser } from '../queries';
const AuthGuard = ({ children }) => (
	<AuthCheck fallback={<Redirect to="/auth/sign-in" />}>{children}</AuthCheck>
);
const AdminGuard = ({ children }) => {
	const user = useOwnUser();
	const isAdmin = () => {
		if (!user) return <Redirect to="/auth/sign-in" />;
		if (user?.admin) {
			return children;
		}
		return <Redirect to="/" />;
	};
	return (
		<AuthCheck fallback={<Redirect to="/auth/sign-in" />}>
			{isAdmin()}
		</AuthCheck>
	);
};

// Auth components
const SignIn = async(() => import('../pages/auth/SignIn'));
const SignUp = async(() => import('../pages/auth/SignUp'));
const ResetPassword = async(() => import('../pages/auth/ResetPassword'));
const Page404 = async(() => import('../pages/auth/Page404'));
const Page500 = async(() => import('../pages/auth/Page500'));
const UpdatePassword = async(() => import('../pages/auth/UpdatePassword'));
const TokenExpire = async(() => import('../pages/auth/TokenExpire'));
const Confirmation = async(() => import('../pages/auth/Confirmation'));

// Pages components
const InvoiceList = async(() => import('../pages/pages/InvoiceList'));
const Conversions = async(() => import('../pages/pages/Conversions'));
const Settings = async(() => import('../pages/pages/Settings'));

// Protected routes

// PostPerk routes
const Brand = async(() => import('../pages/pages/Brand'));
const Offer = async(() => import('../pages/pages/Offer'));
const Partners = async(() => import('../pages/pages/Partners'));
// const EmailTemplate = async(() => import('../pages/pages/EmailTemplate'));

//Admin Routes
const Brands = async(() => import('../pages/pages/Admin/Brands'));

// This route is only visible while signed in
const brandRoutes = {
	id: 'Brand',
	header: 'Menu',
	containsHome: true,
	path: '/brand',
	icon: <ShoppingBag />,
	component: Brand,
	guard: AuthGuard
};

const offerRoutes = {
	id: 'Offer',
	path: '/offer',
	icon: <Gift />,
	component: Offer,
	guard: AuthGuard
};

const partnersRoutes = {
	id: 'Manage Partners',
	path: '/partners',
	icon: <Briefcase />,
	component: Partners,
	guard: AuthGuard
};

const invoiceRoutes = {
	id: 'Invoices',
	path: '/invoices',
	icon: <FileText />,
	component: InvoiceList,
	guard: AuthGuard
};

const conversionRoutes = {
	id: 'Conversions',
	path: '/conversions',
	icon: <ShoppingCart />,
	component: Conversions,
	children: null,
	guard: AuthGuard
};

const settingRoutes = {
	id: 'Settings',
	path: '/settings',
	icon: <SettingsIcon />,
	component: Settings,
	children: null,
	guard: AuthGuard
};

const adminRoutes = {
	id: 'All Brands',
	path: '/admin/brands',
	icon: <ShoppingBag />,
	header: 'Admin',
	children: null,
	component: Brands,
	onlyAdmin: true,
	guard: AdminGuard
};

const authRoutes = {
	id: 'Auth',
	path: '/auth',
	icon: <Users />,
	children: [
		{
			path: '/auth/sign-in',
			name: 'Sign In',
			component: SignIn
		},
		{
			path: '/auth/sign-up',
			name: 'Sign Up',
			component: SignUp
		},
		{
			path: '/auth/reset-password',
			name: 'Reset Password',
			component: ResetPassword
		},
		{
			path: '/auth/404',
			name: '404 Page',
			component: Page404
		},
		{
			path: '/auth/500',
			name: '500 Page',
			component: Page500
		},
		{
			path: '/auth/action',
			name: 'Update Password',
			component: UpdatePassword
		},
		{
			path: '/auth/error',
			name: 'Token Expired',
			component: TokenExpire
		},
		{
			path: '/auth/confirmation',
			name: 'Success',
			component: Confirmation
		}
	],
	component: null
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
	partnersRoutes,
	brandRoutes,
	offerRoutes,
	conversionRoutes,
	invoiceRoutes,
	settingRoutes,
	adminRoutes
];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = [
	brandRoutes,
	offerRoutes,
	partnersRoutes,
	conversionRoutes,
	invoiceRoutes,
	settingRoutes,
	adminRoutes
];
