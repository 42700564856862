import { useFirestoreDocData, useFirestore, useUser } from 'reactfire';

const useOwnUser = () => {
	const { data: user } = useUser();
	const userDetailsRef = useFirestore().collection('user').doc(user?.uid);
	const { data: userDetails } = useFirestoreDocData(userDetailsRef, {
		idField: 'docId'
	});
	return userDetails;
};

export default useOwnUser;
