import React from 'react';
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect,
	useHistory
} from 'react-router-dom';
import { dashboardLayoutRoutes, authLayoutRoutes } from './index';

import DashboardLayout from '../layouts/Dashboard';
import AuthLayout from '../layouts/Auth';
import Page404 from '../pages/auth/Page404';

const childRoutes = (Layout, routes) => {
	return routes.map(
		({ component: Component, guard, children, path }, index) => {
			const Guard = guard || React.Fragment;

			return children ? (
				children.map((element, index) => {
					const Guard = element.guard || React.Fragment;
					const ElementComponent =
						element.component || React.Fragment;
					return (
						<Route
							key={index}
							path={element.path}
							exact
							render={(props) => (
								<Layout>
									<Guard>
										<ElementComponent {...props} />
									</Guard>
								</Layout>
							)}
						/>
					);
				})
			) : Component ? (
				<Route
					key={index}
					path={path}
					exact
					render={(props) => {
						return (
							<Layout>
								<Guard>
									<Component {...props} />
								</Guard>
							</Layout>
						);
					}}
				/>
			) : null;
		}
	);
};
const Root = () => {
	const history = useHistory();
	React.useEffect(() => {
		return history.listen((location) => {
			window.analytics.page(location.pathname);
		});
	}, [history]);
	return (
		<Switch>
			<Route exact path="/">
				<Redirect to="/partners" />
			</Route>

			{childRoutes(DashboardLayout, dashboardLayoutRoutes)}
			{childRoutes(AuthLayout, authLayoutRoutes)}
			<Route
				render={() => (
					<AuthLayout>
						<Page404 />
					</AuthLayout>
				)}
			/>
		</Switch>
	);
};
const Routes = () => {
	return (
		<Router>
			<Root />
		</Router>
	);
};

export default Routes;
